import $ from 'jquery';

$(document).on('click', '.image_box', function() {
  const image_path = $(this).attr('img-path')
  const image_template = '![](' + image_path + ' "")'
  $(this).append('<textarea class="clipboard_textarea">' + image_template + '</textarea>');
  $('.clipboard_textarea').select();
  document.execCommand('copy');
  $('.clipboard_textarea').remove();
});
